import React from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import ContactForm from '../ContactForm/ContactForm'
import JoinOurTeamForm from './JoinOurTeamForm'

const JoinOurTeamModal = (props) => (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    <Modal.Header closeButton>
        <Row>
            <Col>
                <div>
                    <h2>Join Our Team</h2>
                </div>
            </Col>
        </Row>
    </Modal.Header>
    <Modal.Body>
        {/* <ContactForm /> */}
        <JoinOurTeamForm />
    </Modal.Body>
</Modal>
)

export default JoinOurTeamModal