import React from 'react'
import { Link } from 'gatsby'

import * as styles from './donateButton.module.css'

const ShopButton = (props) => (
    <div className={styles.buttonContainer}>
    <Link to={props.btnLink} className={`btn btn-primary btn-sm ${styles.shopButton}`}>
        {props.btnText}
    </Link>
    </div>
)

export default ShopButton;
