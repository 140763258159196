import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink} from "@fortawesome/pro-solid-svg-icons"
import JoinOurTeamModal from "./JoinOurTeamModal"
import * as styles from "../DonateButton/donateButton.module.css"
import { propTypes } from "react-bootstrap/esm/Image"

function Modal(props) {
    const [modalShow, setModalShow] = useState(false)

    return (
        <>
    <div className={styles.buttonContainer}>
    <div className={`btn btn-primary btn-sm ${styles.teamButton}`} onClick={() => setModalShow(true)}
        style={{
            whiteSpace: 'nowrap',
            width: '100%'
        }} >
        {props.btnTitle}
    </div>
    </div>
            <JoinOurTeamModal 
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Modal