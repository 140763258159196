import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button, Row, Col, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLevelUpAlt } from "@fortawesome/pro-solid-svg-icons"
import RECAPTCHA from "react-google-recaptcha"

export default function ContactForm() {
    const [message, setMessage] = useState("")
    
    const {register, formState: {errors, isValid, isSubmitted} , reset,watch} = useForm({
        mode: "onChange"
    });
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const watchObject = watch();
    const [disableSubmit, setDisableSubmit] = useState(true)

    console.log(watchObject)
   
    const handleChange = async (event) => {
      setError(event.error ? event.error.message : "");
      console.log(watchObject)
  }

    const handleSubmit = async ev => {

    // Gatsby check to make sure document is defined
    let data = null
        if (typeof document !== undefined) {
            data = document.forms["contact-form"]
          }
          ev.preventDefault();
          setProcessing(true);
          setDisableSubmit(true);
          
          //alert(JSON.stringify(watchObject));
          //setSucceeded(true);
        //console.log(data);
        fetch('https://3tr9ou3nrg.execute-api.us-west-2.amazonaws.com/production/uss-idaho-contact-form', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
        },            
            body: JSON.stringify(watchObject)
    })
    .then((response) => {
        if (response.status === 200) {
            console.log(JSON.stringify(watchObject));
            //setMessage('Message Sent!');
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            reset();
            setDisableSubmit(true);
        } else {
            console.log('network error')
        }
    })
    .then(
      fetch(
          `https://script.google.com/macros/s/AKfycbw-eCJsWfT0NokmyZRiVCb5eQls-aTkdJuZY1UKZo6PaEoOGO0p/exec`,
          {
              method: "POST",
              body: new FormData(data),
          }
      )
      .then((response) => {
          if (response.status === 200) {
            console.log('Data submitted to Google Sheet') 
          } else {
              console.log('Error submiting to Google Sheet')
          }
      })
      .catch((error) => console.log('Google error!', error.message))
  )       
    setTimeout((data) => { 
    }, 4000)
    
  }

  const SubmitSuccessMessage = () => (
    <div className="submit-success-message text-center">
      <Alert variant="success">
        <h3>Thank you for your submission.</h3>
        <p>A representative will be in touch shortly.</p>
        </Alert>
    </div>
)
    return (
        <div>
          { succeeded ? <SubmitSuccessMessage /> : (
            <Form onSubmit={handleSubmit}
                id="contact-form"
                name="contact-form"
            >
              <input type="hidden" name="form_type" id="form_type" {...register('form_type')} value="Contact Us" />
            <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="Name"
            placeholder="Name (required)"
            {...register("Name", { required: true })}
          />
           {" "}{errors.Name && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
        <Form.Group>
          <Form.Control
          className="formField"
            type="email"
            name="Email"
            placeholder="Email (required)"
            {...register("Email", { required: "Enter your e-mail",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Enter a valid e-mail address",
            }, })}
          />
          {" "}{errors.Email && <span className="formError">{errors.Email.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
        <Form.Group>
          <Row>
          <Col>
          <Form.Control
          className="formField"
            type="text"
            name="Phone"
            required
            placeholder="Phone (mobile preferred)"
            {...register("Phone", { required: true, pattern: {
                  value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  message: "Please enter a valid phone number"
                }
            }
            )}
          />
          {" "}{errors.Phone && <span className="formError">{errors.Phone.message} <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
          </Col>
          <Col>
          <Form.Control
          className="formField"
            type="text"
            name="Zip"
            placeholder="Zip code or country where you reside"
            {...register("Zip")}
          />
          </Col>
          </Row>
        </Form.Group>

        <Form.Row className="my-2">
        <Col>
              <Form.Group>
                <Form.Control className="formField"
                placeholder="Questions or comments for the committee? (required)"
                 as="textarea" name="questions" {...register("questions", { required: true })} />
                 {" "}{errors.questions && <span className="formError">Please enter a message <FontAwesomeIcon
                          icon={faLevelUpAlt}
                          style={{
                              fontSize: '14px',
                          }}
                          aria-hidden="true"
                      /></span> }
              </Form.Group> 
              </Col>
        </Form.Row>              

        <Form.Row>
        <Col>
        <Form.Group>
          <Form.Control className="formField"
          placeholder="How did you hear about us?"
           as="textarea" name="howHeard" {...register('howHeard')} />
           {" "}{errors.howHeard && <span className="formError">Please enter a message <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group> 
        </Col>
        </Form.Row>
        <Form.Group as={Row}>
          <Col>
          <Form.Label as="legend">
                Are you interested in volunteering to assist the Comissioning Committee?
              </Form.Label>
          <fieldset>
              <input
              className="radio"
                type="radio"
                label="Yes"
                name="Volunteer"
                id="volunteer_yes"
                value="Yes"
                {...register('Volunteer')}
                
              /><label className="radioLabel" htmlFor="volunteer_yes">Yes</label>
              <input
              className="radio"
                type="radio"
                label="No"
                name="Volunteer"
                id="volunteer_no"
                value="No"
                {...register('Volunteer')}
              /><label className="radioLabel" htmlFor="volunteer_no">No</label>
              </fieldset>
              </Col>            
          </Form.Group>
          <Form.Row>
          {watchObject.Volunteer === "Yes" ? (
            <>
              <Col>
              <Form.Group>
                <Form.Control className="formField"
                placeholder="Please describe how you'd like to become involved"
                 as="textarea" name="involved" {...register('involved')} />
                 {" "}{errors.involved && <span className="formError">Please enter a message <FontAwesomeIcon
                          icon={faLevelUpAlt}
                          style={{
                              fontSize: '14px',
                          }}
                          aria-hidden="true"
                      /></span> }
              </Form.Group> 
              </Col>
              <Col>
              <Form.Row>
                <Col>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="address"
            placeholder="Mailing Address"
            {...register(`address`)}
            
          />
           {" "}{errors.address && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressCity"
            placeholder="City"
            {...register(`addressCity`)}
          />
           {" "}{errors.addressCity && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressState"
            placeholder="State"
            {...register(`addressState`)}
          />
           {" "}{errors.addressState && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
                <Col sm={4}>
                <Form.Group>
               <Form.Control
            className="formField"
            type="text"
            name="addressZip"
            placeholder="Zip"
            {...register(`addressZip`)}
          />
           {" "}{errors.addressZip && <span className="formError">Cannot be blank <FontAwesomeIcon
                    icon={faLevelUpAlt}
                    style={{
                        fontSize: '14px',
                    }}
                    aria-hidden="true"
                /></span> }
        </Form.Group>
                </Col>
              </Form.Row>
              </Col>
              </>
              ) : null }
          </Form.Row>

          <Form.Group className="my-2">
        <Form.Label as="legend">
              Do you have any connections to the Navy or other branches of our armed forces?
            </Form.Label>
         
           <input
              className="radio"
              type="radio"
              label="Yes"
              name="Connections"
              id="connections_yes"
              value="Yes"
             {...register('Connections')}
            />
            <label className="radioLabel" htmlFor="connections_yes">Yes</label>
            <input
            className="radio"           
              type="radio"
              label="No"
              name="Connections"
              id="connections_no"
              value="No"
              {...register('Connections')}
              
            />
            <label className="radioLabel" htmlFor="connections_no">No</label>
            
                       
        </Form.Group>
        <Form.Row className="my-4">
        
        <Col sm={12}>
        <Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="Sign up for our newsletter?" name="Newsletter"
      className="checkbox"
              id="newsletter_yes"
              value="Yes"
              {...register('Newsletter')}
              
              />
  </Form.Group>
            </Col>            
        </Form.Row>
        
            <div className="my-2">
              {/* <RECAPTCHA 
                sitekey="6LeuCCIaAAAAAEHsnYs6bu5U1tOkLvw6NXcFh5pK"
                size="normal"

                onChange={useCallback(() => setDisableSubmit(false))}
              /> */}
            </div>     
            
              <Button type="submit" variant="primary" form="contact-form" disabled={!isValid} className="submitButton teamButton">
            {isSubmitted
                ? "Message submitted"
                : processing
                ? "Submitting..."
                : "Submit"}
                {/* <span id="button-text">
                                { succeeded ? (
                                  'Message sent!'
                                ) :
                                processing ? (
                                    'Submitting...'
                                ) : 'Submit' }
                            </span> */}
            </Button>
            
            
        
            </Form> )
}
</div>
        
    )
}
