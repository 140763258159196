import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"
import React from "react"

import * as styles from "./header.module.css"
import NavBar from "./NavBar"

const Header = ({ siteTitle }) => (
  <header style={{
    position: 'sticky',
    top: 0,
    zIndex: 1000
  }}>
    <Container fluid className={styles.headerContainer}>
      <div>
      <NavBar />
      </div>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
