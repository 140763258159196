
import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import "./layout.css"


if (typeof window !== "undefined") {
  // target only certain links:
  //require("smooth-scroll")('a[href*="#idaho-naval-history"], a[href*="#uss-idaho-ships"]',
  require("smooth-scroll")('a[href*="#"]',
  {
    ignore: '#history-dropdown, #about-dropdown, #submarine-dropdown, #construction-dropdown, #supporters-dropdown, #news-dropdown, #store-dropdown, #contact-form, #corporate, #partners, #individuals, #civic',
    topOnEmptyHash: false,
    speed: 100
    // offset is set in CSS as .jumpTarget
    //offset: 132
  }  
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className="site">
      <Header siteTitle={data.site.siteMetadata.title} />
        <main className="site-content">
          {children}
        </main>
    <Footer />
    </div>
  )
}


export default Layout
