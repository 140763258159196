import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 200px;
  height: 60px;
  
  .cls-1 {
    fill: #426690;
  }
  .cls-2 {
    fill: #fff;
  }
`;

const UssIdahoLogo = () => {
    return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 773.32249 233.90912">
  <title>uss-idaho-logo</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M568.63716,36.134V11.694a8.1492,8.1492,0,0,1,8.37964-9.42687c6.628-.41285,33.16852-4.888,53.41883,0,.6983,2.09487,0,24.44,0,24.44L646.98005,39.404s77.151,2.98314,103.09951,9.76406,23.15527,21.88017,23.15527,21.88017c0,26.3301-23.39255,25.48739-23.39255,25.48739l-1.3966,6.98288H695.37616s-41.89709,3.84054-52.37136,3.84054-462.26492-4.18969-462.26492-4.18969-58.656-3.49143-73.32-8.37944c-1.74571,4.53891-11.98727,3.14231-11.98727,3.14231a12.90567,12.90567,0,0,1-13.03476,7.44835,18.37435,18.37435,0,0,0-1.62931,5.35354s-15.595,1.16379-16.29332,1.16379,0-25.371,0-25.371L37.70775,78.84577v7.2156s.46552,7.68112-8.37944,5.35354S4.42263,84.89758,4.42263,84.89758,0,86.29413,0,68.837,4.65517,52.77642,4.65517,52.77642l22.81077-4.888s9.54324-3.25867,9.776.69827.23276,10.00875.23276,10.00875L63.544,52.3109l.46551-32.58663A41.19109,41.19109,0,0,1,81.93218,19.026c2.09487,4.18969,7.91392,27.93137,7.91392,27.93137s67.26844-10.707,90.54464-10.707S568.63716,36.134,568.63716,36.134Z"/>
      <g>
        <path class="cls-1" d="M.30785,129.33783h27.875v63.75879c0,11.70508,5.39013,16.47949,15.09277,16.47949h3.542c9.70264,0,15.09278-4.77441,15.09278-16.47949V129.33783H89.78587v64.6836c0,27.25879-17.71093,39.88769-44.66211,39.88769-27.10546,0-44.81591-12.6289-44.81591-39.88769Z"/>
        <path class="cls-1" d="M149.99583,127.95209a154.58606,154.58606,0,0,1,26.64307,2.31055v21.25293l-.77.76953a208.37758,208.37758,0,0,0-24.79492-1.54c-17.86475,0-21.40674,2.61816-21.40674,8.31641v1.38574c0,4.46679,1.07764,6.31445,12.47412,8.16308l13.24463,2.30957c19.09717,3.23438,27.25928,12.167,27.25928,28.64551,0,21.40723-10.16455,34.03516-47.896,34.03516A194.24923,194.24923,0,0,1,103.178,231.291V209.1142l.61573-.61621a206.66925,206.66925,0,0,0,30.03173,2.30957c17.09473,0,21.25293-2.61816,21.25293-9.70215V199.873c0-5.38965-1.23242-7.084-15.86279-9.70215L125.509,187.707c-15.709-2.61816-23.56348-11.39648-23.56348-27.72168C101.94554,140.581,113.03441,127.95209,149.99583,127.95209Z"/>
        <path class="cls-1" d="M239.93187,127.95209a154.58628,154.58628,0,0,1,26.64307,2.31055v21.25293l-.77.76953a208.37792,208.37792,0,0,0-24.79493-1.54c-17.86474,0-21.40673,2.61816-21.40673,8.31641v1.38574c0,4.46679,1.07763,6.31445,12.47412,8.16308l13.24463,2.30957c19.09716,3.23438,27.25927,12.167,27.25927,28.64551,0,21.40723-10.16455,34.03516-47.896,34.03516A194.24912,194.24912,0,0,1,193.114,231.291V209.1142l.61572-.61621a206.66793,206.66793,0,0,0,30.03125,2.30957c17.09522,0,21.25342-2.61816,21.25342-9.70215V199.873c0-5.38965-1.23242-7.084-15.86279-9.70215L215.44505,187.707c-15.709-2.61816-23.56347-11.39648-23.56347-27.72168C191.88158,140.581,202.97044,127.95209,239.93187,127.95209Z"/>
        <path class="cls-1" d="M348.80785,129.33783v102.877H321.54906v-102.877Z"/>
        <path class="cls-1" d="M366.05492,129.33783h38.65625c38.19336,0,50.668,16.94141,50.668,50.82227,0,33.57422-9.85645,52.05469-49.74415,52.05469H366.05492Zm40.34961,79.46778c5.39062,0,11.55078,0,16.17089-4.3125,3.542-3.2334,5.39063-9.54786,5.39063-18.63477v-10.626c0-7.85449-.92383-14.16894-4.3125-17.71093-3.542-3.542-8.77832-4.77442-17.09473-4.77442H393.31468v56.0586Z"/>
        <path class="cls-1" d="M563.33324,231.13666l-.76953,1.07813H534.842L528.22,210.80756H492.18285l-6.623,21.40723H457.99242l-.76953-1.07813,35.11328-101.79883h35.72949Zm-53.74805-78.23535c-3.542,13.24414-7.5459,27.10547-11.39648,39.11719H522.2141c-3.85059-12.01172-7.70118-25.873-11.24317-39.11719Z"/>
        <path class="cls-1" d="M660.19847,129.33783v102.877H632.93968v-40.042H599.67406v40.042H572.41429v-102.877h27.25977V168.3017h33.26562V129.33783Z"/>
        <path class="cls-1" d="M722.10277,127.64447c27.56738,0,48.667,15.86231,48.667,53.44043,0,34.34278-17.55762,52.82422-48.667,52.82422s-48.51172-18.94336-48.51172-52.82422C673.59105,143.50678,694.68968,127.64447,722.10277,127.64447Zm0,81.77735c14.63086,0,20.791-8.93262,20.791-22.94727V174.92377c0-14.01465-6.16015-22.793-20.791-22.793-14.62988,0-20.791,8.77832-20.791,22.793v11.55078C701.31175,200.6435,707.62621,209.42182,722.10277,209.42182Z"/>
      </g>
      <g>
        <path class="cls-2" d="M385.13451,46.40717a78.275,78.275,0,0,1,13.49463,1.16992V58.34076l-.39063.39063a105.34482,105.34482,0,0,0-12.55811-.78028c-9.04834,0-10.84228,1.32618-10.84228,4.21192v.70215c0,2.26269.5459,3.19824,6.31836,4.13476l6.708,1.16992c9.67285,1.6377,13.80664,6.16211,13.80664,14.50879,0,10.8418-5.14844,17.23828-24.25879,17.23828A98.39216,98.39216,0,0,1,361.42162,98.747V87.51459l.312-.3125A104.62967,104.62967,0,0,0,376.94408,88.372c8.6582,0,10.76416-1.32617,10.76416-4.91406v-.624c0-2.7295-.624-3.58789-8.03418-4.91407l-6.94239-1.248c-7.95605-1.32617-11.93408-5.77246-11.93408-14.04C360.79759,52.80268,366.4138,46.40717,385.13451,46.40717Z"/>
        <path class="cls-2" d="M430.68578,46.40717a78.27643,78.27643,0,0,1,13.49511,1.16992V58.34076l-.38965.39063a105.3748,105.3748,0,0,0-12.55859-.78028c-9.04883,0-10.84277,1.32618-10.84277,4.21192v.70215c0,2.26269.5459,3.19824,6.31836,4.13476l6.708,1.16992c9.67188,1.6377,13.80665,6.16211,13.80665,14.50879,0,10.8418-5.14844,17.23828-24.25879,17.23828A98.41139,98.41139,0,0,1,406.97289,98.747V87.51459l.3125-.3125A104.6311,104.6311,0,0,0,422.49632,88.372c8.65821,0,10.76367-1.32617,10.76367-4.91406v-.624c0-2.7295-.624-3.58789-8.03418-4.91407l-6.94238-1.248c-7.95605-1.32617-11.93359-5.77246-11.93359-14.04C406.34984,52.80268,411.96507,46.40717,430.68578,46.40717Z"/>
        <path class="cls-2" d="M453.61742,47.10834h15.13281L480.762,66.06342c1.248,2.02832,4.68066,8.81445,7.17676,13.88476l.54589-.07812c-.78027-5.69434-1.87207-14.35254-1.87207-16.459V47.10834h13.80664V99.21479h-14.04l-12.793-20.28028c-1.248-1.95019-4.29-7.957-6.63086-12.24707l-.46778.07813c.3125,3.042.93653,10.45215.93653,13.65039V99.21479H453.61742Z"/>
        <path class="cls-2" d="M564.45628,47.10834v11.623l-21.373,40.4834H528.34007l-.31152-.38965L543.551,70.27533c1.01367-1.79394,5.92871-9.2041,7.332-11.15429v-.38965H524.28441v-11.623Z"/>
        <path class="cls-2" d="M607.19945,65.7519c0,12.24609-4.60254,21.06054-16.30274,33.46289H575.60765l-.31152-.38965c10.6084-11.1543,15.67871-16.77149,17.94043-21.99707l-.46778-.23438c-1.95019,2.34082-4.915,3.43262-9.8291,3.43262-9.51562,0-14.97656-5.69434-14.97656-15.36719,0-12.24609,7.72266-18.4082,19.89062-18.4082C600.33421,46.25092,607.19945,52.95893,607.19945,65.7519ZM594.7971,69.96381c.07813-.78027.07813-1.63867.07813-2.49609V63.64545c0-4.36816-1.95117-6.55273-7.09863-6.55273-5.46,0-7.48829,2.26269-7.48829,6.085v2.72949c0,3.43262,1.79395,4.91406,6.78614,4.91406A29.30684,29.30684,0,0,0,594.7971,69.96381Z"/>
        <path class="cls-2" d="M650.801,65.7519c0,12.24609-4.60254,21.06054-16.30273,33.46289H619.20921l-.31152-.38965c10.6084-11.1543,15.67871-16.77149,17.94043-21.99707l-.46777-.23438c-1.9502,2.34082-4.915,3.43262-9.82911,3.43262-9.51562,0-14.97656-5.69434-14.97656-15.36719,0-12.24609,7.72266-18.4082,19.89063-18.4082C643.93578,46.25092,650.801,52.95893,650.801,65.7519Zm-12.40234,4.21191c.07812-.78027.07812-1.63867.07812-2.49609V63.64545c0-4.36816-1.95117-6.55273-7.09863-6.55273-5.46,0-7.48828,2.26269-7.48828,6.085v2.72949c0,3.43262,1.79394,4.91406,6.78613,4.91406A29.30693,29.30693,0,0,0,638.39867,69.96381Z"/>
      </g>
    </g>
  </g>
</Logo> 
    )
}

export default UssIdahoLogo;

