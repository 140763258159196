import React from "react"
import { Link } from "gatsby"
import { Form, Button, Row, Col } from "react-bootstrap"

import addToMailchimp from "gatsby-plugin-mailchimp"

import * as styles from "./footer.module.css"

class FooterNewsLetter extends React.Component {
  state = {
    FNAME: null,
    EMAIL: null,
  }

  handleChange = event => {
    console.log({
      [`${event.target.name}`]: event.target.value,
    })
    this.setState({
      [`${event.target.name}`]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    console.log("submit:", this.state)

    addToMailchimp(this.state.EMAIL, this.state)
      .then(({ msg, result }) => {
        console.log("msg", `${result}: ${msg}`)

        if (result !== "success") {
          throw msg
        }
        let successMessage = document.querySelector(".form-container")
        successMessage.innerHTML =
          '<p style="color: white; text-align: center">Thank you for subscribing!</p>'
        //alert(msg);
      })
      .catch(err => {
        console.log("err", err)
        alert(err)
      })
  }

  render() {
    return (
      <div className="form-container mt-4">
        <h4 className="text-center">Sign up for our newsletter</h4>

        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <input
                onChange={this.handleChange}
                autoComplete="fname"
                name="FNAME"
                id="FNAME"
                type="text"
                className="form-control"
                placeholder="First Name"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <input
                name="EMAIL"
                id="EMAIL"
                type="EMAIL"
                onChange={this.handleChange}
                className="form-control"
                placeholder="Email Address"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button
                className="ussIdahoButton btn-block"
                type="submit"
                value="Subscribe"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    )
  }
}

export default FooterNewsLetter
