import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap"
import UssIdahoLogo from "./uss-idaho-logo"
import UssIdahoMobileLogo from "./uss-idaho-mobile-logo"
import * as styles from "./header.module.css"
import DonateButton from "../DonateButton/DonateButton"
import TeamButton from "../DonateButton/TeamButton"
import GolfButton from "../DonateButton/GolfButton"
import ShopButton from "../DonateButton/ShopButton"
import JoinOurTeamModalButton from "../JoinOurTeam/JoinOurTeamModalButton"
import SlideMenu from "./SlideMenu"
import styled from "styled-components"

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    Back to Homepage
  </Tooltip>
)

const DesktopNav = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`

const TabletHeaderButtons = styled.div`
  display: none;
  @media (max-width: 1699px) and (min-width: 1080px) {
    display: flex;
  }
`

const NavBar = props => {
  const toggleDropdown = e => {
    var elem = document.getElementById("history-dropdown").nextElementSibling
      .classList
    elem.contains("show") ? elem.remove("show") : elem.add("show")
  }

  const toggleConstructionDropdown = e => {
    var elem = document.getElementById("construction-dropdown")
      .nextElementSibling.classList
    elem.contains("show") ? elem.remove("show") : elem.add("show")
  }

  const toggleSupportersDropdown = e => {
    var elem = document.getElementById("supporters-dropdown").nextElementSibling
      .classList
    elem.contains("show") ? elem.remove("show") : elem.add("show")
  }

  return (
    <Container
      fluid
      style={{
        padding: 0,
      }}
    >
      <Navbar
        collapseOnSelect
        expand="xl"
        className={`navbar-expand-xl ${styles.navBarExpand}`}
        variant="light"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <Link to="/">
            <Navbar.Brand className="brand">
              <div className="d-none d-xl-block headerLogo">
                <OverlayTrigger
                  placement="right"
                  deplay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <div>
                    <UssIdahoLogo />
                  </div>
                </OverlayTrigger>
              </div>
              <div className="d-xl-none">
                <UssIdahoLogo />
              </div>
            </Navbar.Brand>
          </Link>
        </div>
        {/* <TabletHeaderButtons>
          <div
            className="buttonsContainer"
            style={{
              display: "flex",
            }}
          >
            <div className="mb-2 text-center">
              <DonateButton
                btnLink="/upcoming-events"
                btnText="Christening Info"
              />
            </div>
            <div className="mb-2 text-center">
              <DonateButton btnLink="/donate" btnText="Donate" />
            </div>
            <div className="mb-2 text-center">
              <JoinOurTeamModalButton btnTitle="Join Our Team" />
            </div>
            <div className="mb-2 text-center">
              <DonateButton btnLink="/about-us#contact-us" btnText="Contact" />
            </div>
            <div className="mb-2">
              <ShopButton btnLink="/products" btnText="Shop" />
            </div>
          </div>
        </TabletHeaderButtons> */}
        <div className="slideMenuToggle">
          <SlideMenu />
        </div>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" collapseOnSelect />
        <Navbar.Collapse id="responsive-navbar-nav justify-content-center"> */}

        <Nav
          className="justify-content-center desktopNav"
          style={{
            flexWrap: "wrap",
          }}
        >
          <NavDropdown title="About Us" id="about-dropdown" alignRight>
            <Link className="dropdown-item" eventKey="1.1" to="/about-us">
              Vision &amp; Mission
            </Link>
            <Link
              className="dropdown-item"
              eventKey="1.2"
              to="/about-us#advisory-board"
            >
              Advisory Board
            </Link>
            <Link
              className="dropdown-item"
              eventKey="1.2"
              to="/about-us#commissioning-committee"
            >
              Commissioning Committee
            </Link>
            <Link
              className="dropdown-item"
              eventKey="1.3"
              to="/about-us#endorsements"
            >
              Proclamations &amp; Endorsements
            </Link>
            <Link
              className="dropdown-item"
              eventKey="1.4"
              to="/about-us#committee-crest"
            >
              Committee Crest
            </Link>
            <Link
              className="dropdown-item"
              eventKey="1.4"
              to="/about-us#contact-us"
            >
              Contact Us
            </Link>
          </NavDropdown>

          <NavDropdown
            title="PCU IDAHO Submarine"
            id="submarine-dropdown"
            alignRight
            className="breakword"
          >
            <Link
              className="dropdown-item"
              eventKey="2.1"
              to="/pcu-idaho-submarine#leadership"
            >
              Leadership
            </Link>
            <Link
              className="dropdown-item"
              eventKey="2.2"
              to="/pcu-idaho-submarine#sponsor"
            >
              Sponsor
            </Link>
            <Link
              className="dropdown-item"
              eventKey="2.3"
              to="/pcu-idaho-submarine#crew"
            >
              Crew
            </Link>
            <Link
              className="dropdown-item"
              eventKey="2.4"
              to="/pcu-idaho-submarine#ships-crest"
            >
              Crest
            </Link>
            <NavDropdown.Divider />
            <NavDropdown.Header eventKey="3.4">THE BOAT</NavDropdown.Header>
            <Link
              className="dropdown-item"
              eventKey="3.4"
              to="/pcu-idaho-submarine#boat-capabilities"
            >
              Capabilities
            </Link>
            <Link
              className="dropdown-item"
              eventKey="3.4"
              to="/pcu-idaho-submarine#boat-mission"
            >
              Missions
            </Link>
          </NavDropdown>

          <NavDropdown
            title="Construction &amp; Commissioning"
            id="construction-dropdown"
            alignRight
            className="breakword"
          >
            <Link
              className="dropdown-item"
              eventKey="4.1"
              to="/construction-commissioning"
            >
              Construction Phases
            </Link>
            <Link
              onClick={() => {
                toggleConstructionDropdown()
              }}
              className="dropdown-item"
              eventKey="4.2"
              to="/construction-commissioning#keel-laying"
            >
              Keel Laying
            </Link>
            <Link
              onClick={() => {
                toggleConstructionDropdown()
              }}
              className="dropdown-item"
              eventKey="4.3"
              to="/construction-commissioning#christening"
            >
              Christening
            </Link>
            <Link
              onClick={() => {
                toggleConstructionDropdown()
              }}
              className="dropdown-item"
              eventKey="4.4"
              to="/construction-commissioning#commissioning"
            >
              Commissioning
            </Link>
            {/* <Link
              onClick={() => {
                toggleConstructionDropdown()
              }
              }
                className="dropdown-item"
                eventKey="4.4"
                to="/construction-commissioning#request-tickets"
              >
                Request Tickets
              </Link> */}
          </NavDropdown>

          <NavDropdown
            title="History"
            alignRight
            collapseOnSelect
            id="history-dropdown"
            className="top-level-dropdown"
          >
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              to="/history#uss-idaho-ships"
              eventKey="5.1"
              className="dropdown-item nav-link"
            >
              USS IDAHO Ships
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              eventKey="5.2"
              className="dropdown-item nav-link"
              to="/history#idaho-naval-history"
            >
              Idaho Naval History
            </Link>

            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.3"
              to="/history#idaho-national-guard"
            >
              Idaho National Guard
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.4"
              to="/history#idaho-army-history"
            >
              Idaho Army History
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.5"
              to="/history#idaho-air-force-history"
            >
              Idaho Air Force History
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.5"
              to="/history#idaho-marine-corps-history"
            >
              Idaho Marine Corps History
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.6"
              to="/history#idaho-submariners-on-eternal-patrol"
            >
              Idaho Submariners on Eternal Patrol
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.7"
              to="/history#idaho-congressional-medal-of-honor-recipients"
            >
              Idaho Congressional Medal of Honor Recipients
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.7"
              to="/history#us-navy-ships-named-for-idaho"
            >
              US Navy Ships Named for Idaho Cities, Counties, and Icons
            </Link>
            {/* <Link
              onClick={() => toggleDropdown()}
                className="dropdown-item nav-link"
                eventKey="5.8"
                to="/history#native-american-warriors"
              >
                Native American Warriors
              </Link> */}
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.9"
              to="/history#submarine-documentaries-books"
            >
              Submarine Documentaries &amp; Books
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.10"
              to="/history#idaho-military-history-documentaries-books"
            >
              Idaho Military History Documentaries &amp; Books
            </Link>
            <Link
              onClick={() => toggleDropdown()}
              className="dropdown-item nav-link"
              eventKey="5.11"
              to="/history#about-the-historian"
            >
              About the Historian
            </Link>
          </NavDropdown>

          <NavDropdown title="Supporters" id="supporters-dropdown" alignRight>
            <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="6.1"
              to="/supporters/corporate"
            >
              Corporate
            </Link>
            <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="6.2"
              to="/supporters/small-business-civic"
            >
              Business/Civic Groups
            </Link>
            <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="6.3"
              to="/supporters/individuals"
            >
              Individuals
            </Link>
            <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="6.3"
              to="/supporters/partners"
            >
              Partners
            </Link>
          </NavDropdown>

          <NavDropdown title="News" id="news-dropdown" alignRight>
            <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="7.0"
              to="/upcoming-events"
            >
              Upcoming Events
            </Link>
            {/* <Link
              onClick={() => {
                toggleSupportersDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/upcoming-events"
            >
              - MON 21 OCT Boise Golf Scramble
            </Link> */}
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/upcoming-events#commissioning-information"
            >
              - Commissioning Information
            </Link>

            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.2"
              to="/events"
            >
              Past Events
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-799-flood-up-timelapse-video"
            >
              - IDAHO 799 Float Off (Footage)
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-799-roll-out-timelapse-video"
            >
              - IDAHO 799 Roll Out (Footage)
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/sun-valley-culinary-institute-culinary-training"
            >
              - Sun Valley Culinary Institute - Culinary Training
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events"
            >
              - Christening Activities
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/native-american-blessing-of-pcu-idaho"
              
            >
              Native American Blessing
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/submarine-veterans-lunch-with-the-pcu-idaho-crew"
              
            >
              Submarine Veterans Lunch with PCU IDAHO
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/pre-christening-reception-at-subforce-museum"
              
            >
              Submarine Force Christening Reception
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/pcu-idaho-christening-march-16-2024"
              
            >
              Christening Ceremony
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/watch-the-replay-of-the-future-uss-idaho-christening"
              
            >
              Idaho Public TV Christening Ceremony Video Recording
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/guests-enjoy-spud-juice-at-outer-light-brewing-post-christening-celebration"
              
            >
              Electric Boat Post-Christening Reception
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/post-christening-reception-at-electric-boat-provides-warmth-and-comfort"
              
            >
              Outer Light Celebration
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item ml-3"
              eventKey="7.1"
              to="/events/christening-evening-reception"
              
            >
              Committee Post-Christening Evening Reception
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-public-televisions-idahos-nuclear-navy-boise-premier-march-11-2024"
            >
              - Boise Premier - Idaho Public TV "Idaho's Nuclear Navy"
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.1"
              to="/events/idaho-public-television-idaho-experience-idahos-nuclear-navy"
            >
              - Idaho Falls Premier - Idaho Public TV "Idaho's Nuclear Navy"
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.2"
              to="/news"
            >
              News Stories &amp; Articles
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.3"
              to="/newsletters"
            >
              Newsletters
            </Link>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="7.3"
              to="/school-of-the-boat"
            >
              School Of The Boat
            </Link>
          </NavDropdown>

          <NavDropdown title="Store" id="store-dropdown" alignRight>
            <Link
              onClick={() => {
                toggleDropdown()
              }}
              className="dropdown-item"
              eventKey="8.1"
              to="/products"
            >
              Ship's Merchandise
            </Link>
          </NavDropdown>
        </Nav>

        {/* <Nav>
            <DonateButton btnLink="/donate" btnText="Donate" />
          </Nav>
          <Nav>
            <TeamButton btnLink="/donate" btnText="Join Our Team" />
          </Nav> */}
        {/* </Navbar.Collapse> */}

        <div className={`desktopNav ${styles.navBarBtnContainer}`}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div className="mb-2 text-center" style={{ width: "50%" }}>
              <DonateButton btnLink="/events" btnText="Christening Info" />
            </div>
            <div className="mb-2 text-center" style={{ width: "50%" }}>
              <DonateButton
                btnLink="/events/idaho-public-television-idaho-experience-idahos-nuclear-navy"
                btnText="Idaho Public TV Documentary"
              />
            </div>
            <div className="mb-2" style={{ width: "50%" }}>
              <DonateButton btnLink="/donate" btnText="Donate" />
            </div>
            <div className="mb-2" style={{ width: "50%" }}>
              <JoinOurTeamModalButton btnTitle="Join Our Team" />
            </div>
            <div className="mb-2" style={{ width: "50%" }}>
              <DonateButton btnLink="/about-us#contact-us" btnText="Contact" />
            </div>

            <div className="mb-2" style={{ width: "50%" }}>
              <ShopButton btnLink="/products" btnText="Shop" />
            </div>
            {/* <div className="mb-2" style={{width: '100%'}}>
            <GolfButton btnLink="/upcoming-events" btnText="Crew Visit JUN-JUL 2022" />
            </div> */}
          </div>
        </div>
      </Navbar>
    </Container>
  )
}

export default NavBar
